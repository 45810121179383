<template>
  <v-row>
    <v-col>
      <div v-html="advice"></div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Additive',
  mounted() {
    this.setResult(this.advice);
  },
  computed: {
    ...mapGetters('testMicro', [
      'formSteps',
      'choiceJourney',
    ]),
    advice() {
      return this.choiceJourney.map((choiceIndex, step) => {
        const advice = this.formSteps[step].advices[choiceIndex];
        return `${advice}<br>`;
      }).join('');
    },
  },
  methods: {
    ...mapActions('testMicro', [
      'setResult',
    ]),
  },
};
</script>
